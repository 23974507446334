/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { ToastContainer, toast, Flip } from 'react-toastify';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/common/layout';

const MessageBanner = styled.div`
    background: rgb(244, 248, 251);
    padding: 2rem;
    margin: 1.5rem;
`;

const Download = (title) => {
    const data = useStaticQuery(graphql`
        query getTemplateQuery {
            file(relativePath: { eq: "template.txt" }) {
                name
                publicURL
            }
        }
    `);
    return (
        <a href={data.file.publicURL} download>
            {title}
        </a>
    );
};

const SubmitInformationDocs = () => {
    const [userInput, setUserInput] = useState({
        name: '',
        gmail: '',
        docsTitle: '',
        userDocs: '',
        content: ''
    });

    const userDocsTitle = userInput.docsTitle.toLowerCase().trim().replace(/\s/g, '-');

    const handleInput = (e) => {
        setUserInput({ ...userInput, [e.target.name]: e.target.value });
    };

    const handleUploadDos = (e) => {
        setUserInput({ ...userInput, [e.target.name]: e.target.files[0] });
    };

    const file = userInput.userDocs;

    const getPostData = (fileContent) => {
        const data = {
            branch: 'main',
            author_email: userInput.gmail,
            author_name: userInput.name,
            content: fileContent,
            commit_message: 'create a new file'
        };
        const token = 'glpat-_vi8gahbNntxfADVzV-6';
        const uploadDocs =
            userInput.userDocs !== ''
                ? axios
                      .post(
                          `https://gitlab.com/api/v4/projects/32924407/repository/files/${userDocsTitle}.md`,
                          data,
                          {
                              headers: {
                                  'Content-Type': 'application/json',
                                  'Private-Token': `${token}`
                              }
                          }
                      )
                      .then(() => {
                          const clearForm = Object.keys(userInput).map((key) => {
                              userInput[key] = '';
                              return userInput;
                          });
                          toast.success('Docs uploaded');
                          setUserInput({ ...userInput, clearForm });
                      })
                      .catch(() => {
                          toast.error('Change Docs title');
                      })
                : toast.error('Please choose File');

        return uploadDocs;
    };

    const submitUserDocs = (e) => {
        e.preventDefault();
        if (file) {
            const reader = new FileReader();
            reader.readAsText(file, 'UTF-8');
            reader.onload = (evt) => {
                getPostData(evt.target.result);
            };
        }
    };

    return (
        <Layout>
            <div>
                <ToastContainer
                    position="top-right"
                    draggable={false}
                    transition={Flip}
                    autoClose={2000}
                    hideProgressBar={false}
                    closeOnClick
                    rtl={false}
                    limit={1}
                    pauseOnFocusLoss
                    del
                    pauseOnHover={false}
                />{' '}
                <MessageBanner>
                    <h4>
                        Wish to contribute 🖊️? Please download the following &nbsp;
                        <span className="form-group">{Download('template')}</span> &nbsp;and make
                        your changes.
                    </h4>
                    <h4>
                        Make sure to read the comments 💬 in the template before doing any changes.
                    </h4>
                    <h4>
                        Else if you have the docs applied with the format that has been provided,
                        then please upload it below 👇🏻
                    </h4>
                </MessageBanner>
            </div>
            <div className="row">
                <div className="col-lg-4 p-0 d-flex mb-2 mx-auto">
                    <div className="input-group-text">
                        <label>
                            <i className="bi bi-cloud-arrow-up fs-1" />
                        </label>
                    </div>
                    <div className="input-group-text fs-5">
                        <label>
                            {userInput.userDocs ? userInput.userDocs.name : 'Upload document'}
                        </label>
                        <input
                            id="file-input"
                            type="file"
                            style={{ visibility: 'hidden' }}
                            className="form-control"
                            name="userDocs"
                            accept=".md"
                            required
                            onChange={handleUploadDos}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <form onSubmit={submitUserDocs} className="col-lg-4 mx-auto mb-2 mt-1">
                    <div className="row justify-content-center border form-group required">
                        <div>
                            <h4 className="form-label label-margin mt-2">
                                Please fill in the below fields with appropriate information
                            </h4>
                        </div>

                        <div>
                            <div className="mb-3 mt-3">
                                <label
                                    htmlFor="exampleInputEmail1"
                                    className="form-label label-margin control-label"
                                >
                                    Name
                                </label>
                                <input
                                    type="text"
                                    className="form-control form-controll"
                                    value={userInput.name}
                                    onChange={handleInput}
                                    name="name"
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label
                                    htmlFor="exampleInputPassword1"
                                    className="form-label label-margin control-label"
                                >
                                    Email
                                </label>
                                <input
                                    type="email"
                                    className="form-control form-controll"
                                    id="exampleInputPassword1"
                                    name="gmail"
                                    value={userInput.gmail}
                                    onChange={handleInput}
                                    required
                                />
                            </div>

                            <div className="mb-3">
                                <label
                                    htmlFor="exampleFormControlTextarea1"
                                    className="form-label label-margin control-label"
                                >
                                    Title of the docs
                                </label>
                                <input
                                    type="text"
                                    className="form-control form-controll"
                                    id="exampleInputPassword1"
                                    name="docsTitle"
                                    value={userInput.docsTitle}
                                    onChange={handleInput}
                                    required
                                />
                            </div>

                            <button
                                type="submit"
                                className="btn btn-secondary secondary label-margin mb-3 mt-2"
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </Layout>
    );
};

export default SubmitInformationDocs;
